<template>
  <div id="app">
    <pc-top-bar v-if="!isMobile"></pc-top-bar>
    <mobile-top-bar v-if="isMobile"></mobile-top-bar>
    <router-view/>
    <pc-footer v-if="!isMobile"></pc-footer>
    <mobile-footer v-if="isMobile"></mobile-footer>
  </div>
</template>

<script>
import PcTopBar from '@/components/pc/topBar.vue'
import mobileTopBar from '@/components/mobile/topBar.vue'
import PcFooter from '@/components/pc/footer'
import mobileFooter from '@/components/mobile/footer'
import { isMobile } from '@/utils/index'
export default {
  name: 'Index',
  components: { PcTopBar, PcFooter, mobileTopBar, mobileFooter },
  data () {
    return {
      isMobile: isMobile()
    }
  },
  created () {}
}
</script>

<style lang="scss">
</style>
