import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import { isMobile } from '@/utils/index'
import '@/styles/index.scss' // global css
import '@/permission'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from '../node_modules/element-ui/lib/locale/lang/en'
import enLang from 'element-ui/lib/locale/lang/en'// 如果使用中文语言包请默认支持，无需额外引入，请删除该依赖
// import { Form, FormItem, Input, Row, Col, RadioGroup, Radio, Select, Option, DatePicker } from 'element-ui'

// Vue.config.productionTip = false
// Vue.use(Form)
// Vue.use(FormItem)
// Vue.use(Input)
// Vue.use(Row)
// Vue.use(Col)
// Vue.use(RadioGroup)
// Vue.use(Radio)
// Vue.use(Select)
// Vue.use(Option)
// Vue.use(DatePicker, { locale })
Vue.use(Element, {
  locale: enLang // 如果使用中文，无需设置，请删除
})
new Vue({
  router,
  store,
  render: h => h(App),
  created () {
    function setRem () {
      const whdef = 100 / 750 // 表示750的设计图,使用100PX的默认值
      const bodyWidth = document.body.clientWidth // 当前窗口的宽度
      const rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
    }
    // pc端
    function setRemPC () {
      const whdef = 100 / 1920 // 表示1920的设计图,使用100PX的默认值
      const bodyWidth = document.body.clientWidth // 当前窗口的宽度
      const rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
      document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
    }
    // 实例创建完成后被立即调用rem换算方法,解决内容闪现问题

    if (isMobile()) {
      // 移动端使用
      setRem()
      window.addEventListener('resize', setRem)// 浏览器窗口大小改变时调用rem换算方法
    } else {
      // pc端使用
      setRemPC()
      window.addEventListener('resize', () => {
        const whdef = 100 / 1920 // 表示1920的设计图,使用100PX的默认值
        const bodyWidth = document.body.clientWidth // 当前窗口的宽度
        const rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        document.getElementsByTagName('html')[0].style.fontSize = rem + 'px'
      })// 浏览器窗口大小改变时调用rem换算方法
    }
  }
}).$mount('#app')
