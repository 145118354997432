<template>
  <div class="pc-header">
    <router-link to="/">
      <div class="logo"></div>
    </router-link>
    <div class="nav" @click="nav = true"></div>
    <div v-show="nav" class="list">
      <div class="close">
        <div class="btn" @click="nav = false"></div>
      </div>
      <!-- <router-link v-for="(e, i) in navList" :key="i" :to="`/${e.path}`"> -->
        <div v-for="(e, i) in navList" :key="i" class="item" :class="curRoute.indexOf(e.path) > -1 ? `active_home ${e.path}` : `normal_home ${e.path}`" @click="switchNav(e.path)">
          {{ e.meta.title }}
          <div class="line"></div>
        </div>
      <!-- </router-link> -->
    </div>
  </div>
</template>

<script>
import mobileRoutes from '@/router/mobile/mobileRouters'
export default {
  data () {
    return {
      nav: false,
      navList: mobileRoutes[0].children
    }
  },
  computed: {
    curRoute () {
      return this.$store.state.curRoute
    }
  },
  methods: {
    switchNav (p) {
      this.$router.push(p)
      this.nav = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .active_home{
    font-weight: bold;
    font-size: 0.44rem;
    font-family:Rozha-one;
  }
  .normal_home{
    font-weight: normal;
  }
  .pc-header {
    display: flex;
    // padding: 0.6rem 0.72rem 1rem 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.72rem;
    height: 1.7rem;
    .logo{
      width: 1.7rem;
      height: 1.7rem;
      background-image: url('@/assets/pc_header_logo.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .nav{
      width: 0.48rem;
      height: 0.44rem;
      background-image: url('@/assets/m-nav.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .list{
      z-index: 9999;
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #efc8cbc9;
      // padding: 0rem 1.4rem 0 1.4rem;
      .close{
        height: 1.7rem;
        background: #D7B4B6;
        padding-right: 0.72rem;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        margin-bottom: 2rem;
        .btn{
          width: 0.5rem;
          height: 0.5rem;
          background-image: url('@/assets/close.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
        }
      }
      .item{
        color: #AD333C;
        cursor: pointer;
        font-size: 0.42rem;
        height: 0.9rem;
        line-height: 0.9rem;
        text-align: center;
        // border-bottom: 2px solid #AD333C;
        padding: 0 1.7rem;
        position: relative;
        margin: 2px 0;
        .line{
          // width: 95%;
          height: 0.04rem;
          background: #AD333C;
        }
      }
    }
  }
</style>
