const meta = {
  charset: 'utf-8',
  name: 'viewport',
  content: 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no'
}
const mobileRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/mobile/mobileIndex.vue'),
    redirect: '/home',
    meta: meta,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/mobile/home.vue'),
        meta: {
          title: 'Home',
          ...meta
        }
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import('@/views/mobile/service.vue'),
        meta: {
          title: 'Service',
          ...meta
        }
      },
      {
        path: 'aboutus',
        name: 'Aboutus',
        component: () => import('@/views/mobile/aboutUs.vue'),
        meta: {
          title: 'About Us',
          ...meta
        }
      },
      {
        path: 'contactUs',
        name: 'ContactUs',
        component: () => import('@/views/mobile/contactUs.vue'),
        meta: {
          title: 'Contact Us',
          ...meta
        }
      }
    ]
  }
]

export default mobileRoutes
