import Vue from 'vue'
import VueRouter from 'vue-router'
import { isMobile } from '@/utils/index'
import mobileRoutes from './mobile/mobileRouters'
import pcRoutes from './pc/pcRouters'

Vue.use(VueRouter)

let routes = []

// 判断那设备注册不同的组件
isMobile() ? routes = mobileRoutes : routes = pcRoutes

const router = new VueRouter({
  routes
})

export default router
