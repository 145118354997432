import router from './router'
import getPageTitle from '@/utils/get-page-title'
import store from './store'

router.beforeEach((to, from, next) => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
  window.pageYOffset = 0
  document.title = getPageTitle(to.meta.title)
  store.commit('SET_CURROUTE', to.path)
  next()
})
