const pcRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/pc/pcIndex.vue'),
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/pc/home.vue'),
        meta: {
          title: 'Home'
        }
      },
      {
        path: 'service',
        name: 'Service',
        component: () => import('@/views/pc/service.vue'),
        meta: {
          title: 'Service'
        }
      },
      {
        path: 'aboutus',
        name: 'Aboutus',
        component: () => import('@/views/pc/aboutUs.vue'),
        meta: {
          title: 'About Us'
        }
      },
      {
        path: 'contactUs',
        name: 'ContactUs',
        component: () => import('@/views/pc/contactUs.vue'),
        meta: {
          title: 'Contact Us'
        }
      }
    ]
  }
]

export default pcRoutes
