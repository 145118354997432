<template>
  <div class="pc_con">
    <router-link to="/">
      <div class="logo"></div>
    </router-link>
    <div class="line"></div>
    <div class="menu">
      <router-link v-for="(e, i) in navList" :key="i" :to="`/${e.path}`">
        <div class="item" :class="curRoute.indexOf(e.path) > -1 ? `active_home ${e.path}` : `normal_home ${e.path}`">
          {{ e.meta.title }}
          <div class="line"></div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import pcRoutes from '@/router/pc/pcRouters'
export default {
  data () {
    return {
      navList: pcRoutes[0].children
    }
  },
  computed: {
    curRoute () {
      return this.$store.state.curRoute
    }
  }
}
</script>

<style lang="scss" scoped>
  .active_home{
    .line{
      width: 80%;
    }
  }
  .normal_home{
    .line{
      width: 0;
    }
  }
  .logo{
    width: 80px;
    height: 80px;
    background-image: url('@/assets/pc_header_logo.png');
    margin: 24px auto 10px auto;
  }
  .line{
    width: 100%;
    height: 1px;
    background: #AD333C;
  }
  .menu{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 48px;
    .item{
      // height: 48px;
      padding: 15px;
      color: #AD333C;
      position: relative;
      cursor: pointer;
      font-size: 16px;
      transition: all linear .2s;
      .line{
        position: absolute;
        height: 1px;
        background: #AD333C;
        bottom: 0;
        transition: all linear .2s;
        left: 50%;
        transform: translate(-50%);
      }
      &:hover{
        font-size: 18px;
        .line{
          width: 80%;
        }
      }
    }
  }
</style>
